html {
	background-color: #000000;
	background-image: url('./images/slash-it.png');
	font-size: 15px;
	/* background-image: url('./images/stardust.png'); */
	/* background-image: url('./images/stardust.png') bottom center; */
}

body {
	height: 100%;
	margin: 0;
	/* font-family: 'Ubuntu Mono', monospace; */
	font-family: 'Ubuntu Mono', monospace;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1.075rem !important;
}

/* code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
} */

.App {
	/* height: 100vh; */
	/* background: #000000; */
	background-color: #000000;
	background-image: url('./images/slash-it.png');
	/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
	/* font-family: 'Ubuntu Mono', monospace; */
	font-family: 'Ubuntu Mono', monospace;
	color: #fff;
	/* new */
	position: relative;
}

.container {
	background: inherit;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	display: flex;
	flex-direction: column;
	height: 100vh;
	/* width: 100%; */
	/* background pink; */
}

.sub-container {
	/* min-height: 50%; */
	flex: 1 0 auto;
}

@media (max-width: 600px) {
	.container {
		width: auto;
	}
	.content {
		padding: 20px !important;
	}
}
@media (min-width: 768px) {
	.container {
		width: auto;
		/* background: yellow; */
	}
}
@media (min-width: 960px) {
	.container {
		max-width: 960px;
		/* background: red; */
	}
}
@media (min-width: 1200px) {
	.container {
		/* max-width: 1140px; */
		max-width: 960px;
		/* background: pink; */
	}
}

.datepickerWrapper * {
	border-color: #e91e63 !important;
	border-width: 1px !important;
	font-family: 'Ubuntu Mono', monospace !important;
}

.datepickerWrapperDisabled * {
	border-color: #72627b !important;
	border-width: 1px !important;
	font-family: 'Ubuntu Mono', monospace !important;
}

.datepicker > * {
	color: white !important;
	font-size: 18px !important;
	/* border-color: #e91e63;
	border-width: 1; */
}

.disabledGetValue {
	background: pink;
}

/*

Header

*/
